<!-- 请求 -->
<template>
  <div>
    <div class="title">
      <div class="title-text"
           :class="{'vn-bold-italic': $i18n.locale == 'vi'}">{{$t('朋友帮助请求')}} :</div>
      <div class="title-img"
           @click="refresh"><img src="@/assets/bracelet_img/task/reload.png"></div>
    </div>
    <div class="list">
      <van-list v-model="loading"
                :class="{'vn-italic': $i18n.locale == 'vi'}"
                :finished="finished"
                :immediate-check="false"
                :offset="20"
                :finished-text="$t('没有更多了')"
                @load="onLoad">
        <van-cell v-for="(item, index) in list"
                  :key="index">
          <div class="list-time">00:32:25</div>
          <div class="list-title">JIMM92</div>
          <div class="list-num">
            <div class="list-num-blok"></div>
            <div class="list-num-text">4655SS...SSD2</div>
            <div class="list-num-blok"></div>
          </div>
          <div class="list-avatar">
            <div class="list-avatar-img"><img src="@/assets/bracelet_img/2.png"></div>
            <div class="list-avatar-grade">11</div>
          </div>
          <div class="list-price">10 SOLARIX</div>
          <div class="list-send" :class="{'vn-italic': $i18n.locale == 'vi'}">{{$t('发送帮助')}}</div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
import { getTasknewList } from "@/api/api";
import { mapState } from "vuex";
export default {
  data () {
    return {
      total: 0,
      page: 1,
      limit: 10,
      list: [],
      loading: false,
      finished: false,
      timer: null,
    };
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  props: {
    tabsIndex: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  watch: {
    tabsIndex () {
      //  console.log('111111111');
      if (this.tabsIndex == 2) {
        this.waitForGlobal();
      }
    }
  },
  methods: {
    onLoad () {
      // 异步更新数据
      getTasknewList({
        type: this.tabsIndex,
        page: this.page,
        limit: this.limit,
      }).then(res => {
        if (res.code == 0) {
          this.total = res.data.total
          this.list = this.list.concat(res.data.data)
          this.loading = false;
          if (res.data.data.length < this.limit) {
            this.finished = true;
          }
          this.page++
        } else {
          this.finished = true;
        }
      })
    },
    refresh () {
      if (!this.loading) {
        this.loading = true
        this.finished = false
        this.list = []
        this.page = 1
        this.onLoad();
      }
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        if (!this.loading) {
          this.loading = true
          this.finished = false
          this.list = []
          this.page = 1
          this.onLoad();
        }
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
  },
}
</script>
<style lang="less" scoped>
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-text {
    font-family: REEJI-PinboGB-Flash;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .title-img {
    width: 22px;
    min-width: 22px;
    height: 22px;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
.list {
  width: 100%;
  height: 60vh;
  margin-top: 5px;
  overflow: auto;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */

  .list-time {
    background-image: linear-gradient(115deg, #a7f264 0%, #2b2b2b 65%),
      linear-gradient(#2b2b2b, #2b2b2b);
    background-blend-mode: normal, normal;
    font-family: KenyanCoffeeRg-BoldItalic;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2b2b2b;
    padding: 0 7px;
  }
  .list-title {
    margin-top: 3px;
    text-align: center;
    font-family: AeroMaticsBoldItalic;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 1;
  }
  .list-num {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    .list-num-text {
      flex: 1;
      font-family: AeroMaticsBold;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #f4f2f4;
      text-align: center;
    }
    .list-num-blok {
      width: 7px;
      height: 9px;
      background-color: #a7f264;
    }
  }
  .list-avatar {
    position: relative;
    .list-avatar-img {
      width: 76px;
      height: 77px;
      border-radius: 100%;
      overflow: hidden;
      margin-top: 11px;
      margin-bottom: 11px;
      margin-left: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .list-avatar-grade {
      position: absolute;
      bottom: -12%;
      right: 18%;
      width: 36px;
      height: 36px;
      background-color: #2b2b2b;
      border: solid 1px #000000;
      border-radius: 100%;
      font-family: AeroMaticsDisplayBoldItalic;
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .list-price {
    width: 86%;
    margin: 18px auto 0 auto;
    background-color: #ffffff;
    border-radius: 8px;
    font-family: AeroMaticsDisplayItalic;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #2b2b2b;
    text-align: center;
  }
  .list-send {
    font-family: REEJI-PinboGB-Flash;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    box-shadow: -1px 3px 4px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 7px 0 11px 0;
    background-image: linear-gradient(to right, #38f8d4 0%, #43ea80 59%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    cursor: pointer;
  }
}
.list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/deep/.van-list {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  //   justify-content: space-between;
  align-items: center;
}
/deep/ .van-cell {
  background-color: transparent;
  padding: 0;
  width: 32%;
  background-color: #2b2b2b;
  border-radius: 8px;
  margin: 0.6%;
}
/deep/ .van-cell::after {
  border: 0;
}
/deep/ .van-list__finished-text {
  width: 100%;
}
/deep/ .van-list__loading {
  width: 100%;
}
</style>