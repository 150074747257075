<template>
  <div class="market">
    <watch-head :title="$t('任务')"
                :img="require('@/assets/bracelet_img/task/flag.png')"></watch-head>

    <div class="tabs">
      <div class="tabs-list"
           :class="{'tabs-list-current': tabsIndex === list.key, 'vn-bold-italic': $i18n.locale == 'vi' }"
           v-for="(list, index) in tabsList"
           :key="index"
           @click="tabsCurrent(list.key)">{{list.title}} </div>
    </div>

    <div class="compoent">
      <daily v-show="tabsIndex === 1" :tabsIndex="tabsIndex"></daily>
      <ask v-show="tabsIndex === 2" :tabsIndex="tabsIndex"></ask>
      <special v-show="tabsIndex === 3" :tabsIndex="tabsIndex"></special>
    </div>

    <watch-foot></watch-foot>
  </div>
</template>
<script>
import watchHead from '@/components/watchHead'
import watchFoot from '@/components/watchFoot/index.vue'
import daily from './components/daily'
import ask from './components/ask'
import special from './components/special'
export default {
  data () {
    return {
      tabsList: [
        { title: this.$t('每日'), key: 1 },
        { title: this.$t('请求'), key: 2 },
        { title: this.$t('特别'), key: 3 },
      ],
      tabsIndex: 0,
    }
  },
  components: {
    watchHead,
    watchFoot,
    daily,
    ask,
    special
  },
  mounted() { this.tabsIndex = 1 },
  methods: {
    tabsCurrent (index) {
     //  console.log(index);
      if (this.tabsIndex !== index) {
        this.tabsIndex = index
      }
    },
  },
}
</script>
<style lang="less" scoped>
.market {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/8.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;

  .tabs {
    width: 98%;
    margin-top: 20px;
    margin-left: 2%;
    border-radius: 25px 0px 0px 3px;
    background-color: rgba(43, 43, 43, 0.5);
    padding: 6px 7px;
    display: flex;
    justify-content: center;
    align-items: center;

    .tabs-list {
      flex: 1;
      text-align: center;
      font-family: REEJI-PinboGB-Flash;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #5c65cb;
      cursor:pointer;
    }
    .tabs-list-current {
      background-color: #a7f264;
      box-shadow: 2px 2px 1px 0px rgba(19, 19, 19, 0.16);
      border-radius: 20px 3px 20px 3px;
      color: #2b2b2b;
    }
  }
  .compoent {
    width: 92%;
    margin: 25px auto 0 auto;
  }
}
</style>